<!--  -->
<script>
export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {},
  created() { 
    this._navigator()
  },
  mounted() { },
  methods: {
    _navigator() {
      if (!/micromessenger/i.test(navigator.userAgent)) {
        this.$router.push({
          path: '/download-user/appdownload'
        })
      }
    }
  }
}
</script>

<template>
  <main>
    <ul class="indicate">
      <li>
        <span>1</span>
        <div>点击右上角的<img src="../image/路径 2675@2x.png" alt="" /> 按钮</div>
      </li>
      <li>
        <span>2</span>
        <div>选择手机自带浏览器中打开</div>
      </li>
    </ul>
    <img class="arrows" src="../image/3252@2x.png" alt="" />
  </main>
</template>

<style scoped>
@import '../css/index.css';
</style>
